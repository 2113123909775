import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
// import 'react-table/react-table.css'

import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardIcon from "../../../components/Card/CardIcon";
import Button from "../../../components/CustomButtons/Button";

// @material-ui/icons
import Report from "../../../icons/report";

// Style of the main page
import withStyles from "@material-ui/core/styles/withStyles";
import manageRulesPageStyle from "../style/manageRulesPageStyle";
import {withTranslation} from "react-i18next";

class ManageRulesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    id: "1234",
                    name: "660 AMD Voucher Check",
                    type: props.t("lunch"),
                    date: "29/02/2020",
                    assigned: 8,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                }
            ]
            }
        };

    render() {
        const {t, classes} = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} lg={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <Report />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>{t("rules_list")}</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={this.state.data}
                                    filterable
                                    columns={[
                                        {
                                            Header: t("rule_name"),
                                            accessor: "name"
                                        },
                                        {
                                            Header: t("type"),
                                            accessor: "type"
                                        },
                                        {
                                            Header: t("due_date"),
                                            accessor: "date"
                                        },
                                        {
                                            Header: t("assigned_people"),
                                            accessor: "assigned",
                                            sortable: false
                                        },
                                        {
                                            Header: " ",
                                            accessor: "action",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

export default withTranslation()(withStyles(manageRulesPageStyle)(ManageRulesPage))