import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "../../../components/Navbars/AuthNavbar";
import Footer from "../../../components/Footer/Footer";

// Views
import LoginView from "../views/Login";

// Image
import bgImage from "../../../assets/img/BackgroundImageLow.jpg";

import styles from "./style/authStyle";

const useStyles = makeStyles(styles);

function Auth(props) {

    const { t, ...rest } = props;

    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();

    React.useEffect(() => {
        document.body.style.overflow = "unset";
        // Specify how to clean up after this effect:
        return function cleanup() {};
    });

    return (
        <div>
            <AuthNavbar {...rest} brandText={t("home")} />
            <div className={classes.wrapper} ref={wrapper}>
                <div
                    className={classes.fullPage}
                    style={{ backgroundImage: "url(" + bgImage + ")" }}
                >
                    <LoginView/>
                    <Footer white />
                </div>
            </div>
        </div>
    );
}

export default Auth
