import React from "react";
import {withFirebase} from "react-redux-firebase";

function SignOut(props) {

    const { firebase, ...rest } = props;

    const signOut = () => {
        firebase.auth().signOut();
    };

    signOut();

    // firebase.auth().signOut);

    return (<h1>Sign Out...</h1>)
}

export default withFirebase(SignOut)