import React from "react";

//Redux
import {connect} from "react-redux";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

//Network
import {firestoreConnect, withFirebase, withFirestore} from "react-redux-firebase";

//Style
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardViewStyle from "../style/dashboardViewStyle"
import {spinnerWhileLoading} from "../../../network/auth";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import Card from "../../../components/Card/Card";

class StoreDashboardView extends React.Component {
    constructor(props) {
        super(props);
    }

    formatDate(date) {
        const {t} = this.props;

        const monthNames = [
            t("jan"),
            t("feb"),
            t("mar"),
            t("apr"),
            t("may"),
            t("jun"),
            t("jul"),
            t("aug"),
            t("sep"),
            t("oct"),
            t("nov"),
            t("dec")
        ];

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    formatTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return hours + ':' + minutes;
    }

    getTableData() {
        const content = [];

        Object.values(this.props.payments).map((value) => {
            content.push([
                        this.formatDate(value.updated.toDate()), //date:
                        this.formatTime(value.updated.toDate()), //time:
                        this.props.t(value.product), //product:
                        value.quantity.toString() + " " + this.props.t(value.quantityUnit), //quantity:
                        value.payerName //company:
                    ]);
            // if (value.status.closed === false) {
            //
            //     if (i < 10) {
            //         tableData.push([
            //             t(value.type),
            //             value.quantity.toString() + " " + t(value.quantity_unit),
            //             value.price.toString() + " " + t(value.price_unit)
            //         ]);
            //     }
            // }
        });

        // this.props.payments.forEach(doc => {
        //     const currentData = doc.data();
        //     content.push({
        //         date: this.formatDate(currentData.updated.toDate()),
        //         time: this.formatTime(currentData.updated.toDate()),
        //         product: this.props.t(currentData.product),
        //         quantity: currentData.quantity,
        //         company: currentData.payerName
        //     });
        // });

        return content
    };

    render() {
        const {t} = this.props;
        return <Card>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={[t("date"), t("time"), t("product"), t("quantity"), t("company")]}
                    tableData={this.getTableData()}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                />
            </CardBody>
        </Card>
    }
}

const enhance = compose(
    withTranslation(),
    withFirebase,
    firestoreConnect((props) => [
        {
            collection: 'payments',
            where: [
                ['store', '==', props.firebase.auth().currentUser.uid]
            ],
            orderBy: ['updated', 'desc']
        }
    ]),
    connect((state, props) => ({
        user: state.firebase.profile,
        payments: state.firestore.ordered.payments
    })),
    withStyles(dashboardViewStyle),
    spinnerWhileLoading(['user', 'payments'])
);

export default enhance(StoreDashboardView);