import React from "react";

// Translation
import { withTranslation } from "react-i18next";

// Styles
import sidebarStyle from "../style/sidebarStyle";

import avatar from "../../../assets/img/avatar_placeholder.jpg";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {NavLink} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import withStyles from "@material-ui/core/styles/withStyles";
import cx from "classnames";

class ProfileSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes)
        };
    }

    getCollapseStates = routes => {
        let initialState = {};
        routes.map(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    openCollapse(collapse) {
        const st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    render() {

        const { classes, bgColor, t, role } = this.props;

        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });

        const collapseItemMini = classes.collapseItemMini;

        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive
            });

        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive
            });

        return (
            <div className={userWrapperClass}>
                <div className={classes.photo}>
                    <img src={avatar} className={classes.avatarImg} alt="..." />
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={role.name}
                                secondary={
                                    <b
                                        className={
                                            classes.caret +
                                            " " +
                                            classes.userCaret +
                                            " " +
                                            (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                    <span className={collapseItemMini}>
                      {t("my_profile_abbreviation")}
                    </span>
                                        <ListItemText
                                            primary={t("my_profile")}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                    <span className={collapseItemMini}>
                      {t("edit_profile_abbreviation")}
                    </span>
                                        <ListItemText
                                            primary={t("edit_profile")}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="#"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                    <span className={collapseItemMini}>
                      {t("settings_abbreviation")}
                    </span>
                                        <ListItemText
                                            primary={t("settings")}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        )
    }

}

export default withTranslation()(withStyles(sidebarStyle)(ProfileSection))