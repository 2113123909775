import React from "react";

// Routing
import { Switch, Route, Redirect } from "react-router-dom";
import dashboardRoute from "../../../routes/dashboardRoute";
import {HOME, DASHBOARD, PROVIDER, STORE, NOT_FOUND, MANAGEMENT, COMPANY, EMPLOYEE} from "../../../config/routePaths";

// Components
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/Navbars/AdminNavbar";

// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// Style Related Modules and Files
import dashboardStyle from "../style/dashboardStyle";
import cx from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {element} from "prop-types";

const useStyles = makeStyles(dashboardStyle);
let ps;

export default function Dashboard(props) {

    const { t, user, firebase, ...rest } = props;

    // Logout
    // firebase.auth().signOut();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [miniActive, setMiniActive] = React.useState(false);

    const [image, setImage] = React.useState(require("../../../assets/img/SideBarLow.jpg"));
    const [color, setColor] = React.useState("blue");
    const [bgColor, setBgColor] = React.useState("black");
    // styles
    const classes = useStyles();
    const mainPanelClasses =
        classes.mainPanel +
        " " +
        cx({
            [classes.mainPanelSidebarMini]: miniActive
            // [classes.mainPanelWithPerfectScrollbar]:
            // navigator.platform.indexOf("Win") > -1
        });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
    React.useEffect(() => {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     // ps = new PerfectScrollbar(classes.mainPanel.current, {
        //     //     suppressScrollX: true,
        //     //     suppressScrollY: false
        //     // });
        //     document.body.style.overflow = "hidden";
        // }
        window.addEventListener("resize", resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {
            // if (navigator.platform.indexOf("Win") > -1) {
            //     ps.destroy();
            // }
            window.removeEventListener("resize", resizeFunction);
        };
    });

    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getActiveRoute = routes => {
        let activeRoute = "payva";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].type + routes[i].path) !== -1
                ) {
                    return t(routes[i].name);
                }
            }
        }
        return activeRoute;
    };

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            const Component = prop.component;
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            return (
                <Route
                    path={prop.type + prop.path}
                    render={() => <Component user={user}/>}
                    key={key}
                />
            );
        });
    };

    const getRoleRoute = () => {
        if (user !== undefined || true) {
        if (user.user !== undefined || true) {
            if (user.user.admin === true) {
                if (user.business !== undefined || true) {
                    if (user.business.provider === true) {
                        return PROVIDER
                    }

                    if (user.business.store === true) {
                        return STORE
                    }

                    if (user.business.company === true) {
                        return COMPANY
                    }

                    if (user.user !== undefined || true) {
                        if (user.user.empployee === true) {
                            return EMPLOYEE
                        }
                    }

                    // if (user.business.merchant === true) {
                    //
                    // }
                }
            } else {
                if (user.user.management === true) {
                    return MANAGEMENT
                }
            }
        } else {
            return NOT_FOUND
        }
        }

        return NOT_FOUND
    };

    const routes = dashboardRoute;

    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                bgColor={bgColor}
                logout={props.logout}
                miniActive={miniActive}
                role={user}
                {...rest}
            />

            <div className={mainPanelClasses} ref={mainPanel}>
                <AdminNavbar
                    sidebarMinimize={sidebarMinimize.bind(this)}
                    miniActive={miniActive}
                    brandText={getActiveRoute(routes)}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                />
                <div className={classes.content}>
                    <div className={classes.container}>
                        <Switch>
                            <Redirect exact from={HOME} to={getRoleRoute() + DASHBOARD} />
                            <Redirect exact from={getRoleRoute()} to={getRoleRoute() + DASHBOARD} />
                            {getRoutes(routes)}
                            {/* ToDo add redirect exact from merchant/ to merchant/finance*/}
                        </Switch>
                    </div>
                </div>
            </div>

        </div>
    )
}