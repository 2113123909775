import React from "react";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import Button from "../../../../components/CustomButtons/Button";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import CardFooter from "../../../../components/Card/CardFooter";

class LoginView extends React.Component {

  constructor(props) {
    super(props);

    this.timer = null;

    this.props = props;

    this.login = {};
    this.state = {
      cardAnimation: "cardHidden"
    }
  };

  componentDidMount = () => {
    this.timer = setTimeout(() => this.setState({cardAnimation: ""}), 700);
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onLoginHandle = () => {
    if (this.login.email !== "maxoil") {
      this.props.login(this.login);
    } else {
      this.props.login({
            email: "accountant.maxhur@mail.ru",
            password: this.login.password
          }
      );
    }

  };

  onEmailChange = e => {
    this.login.email = e.target.value.toString()
  };

  onPasswordChange = e => {
    this.login.password = e.target.value.toString()
  };

  render() {
    const { t, classes } = this.props;
    return (
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[this.state.cardAnimation]}>
                  <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="primary"
                  >
                    <h4 className={classes.cardTitle}>{t("log_in")}</h4>
                    <div className={classes.socialLine}>

                    </div>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                        labelText={t("email_hint")}
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon}/>
                              </InputAdornment>
                          ),
                          onChange: this.onEmailChange
                        }}
                    />
                    <CustomInput
                        labelText={t("password_hint")}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                <Lock className={classes.inputAdornmentIcon}/>
                              </InputAdornment>
                          ),
                          type: "password",
                          autoComplete: "off",
                          onChange: this.onPasswordChange
                        }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button color="primary" simple size="lg" block onClick={this.onLoginHandle}>
                      {t("login_button")}
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
    )
  };
}

export default LoginView;
