import React from "react";

import { compose } from 'redux'
import { connect } from 'react-redux'
import {firestoreConnect, withFirebase, withFirestore} from 'react-redux-firebase'
import {withHandlers} from "recompose";
import {spinnerWhileLoading} from "../../../network/auth";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// Translation
import { withTranslation } from "react-i18next";

// Material UI Core Component
import Tooltip from "@material-ui/core/Tooltip";

// Local components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardViewStyle from "../style/dashboardViewStyle";
import ReactTable from "react-table";
import FilterList from "@material-ui/icons/FilterList";
import CalendarPickerView from "./CalendarPickerView";


class ReportView extends React.Component {

    state = {
        payments: this.props.payments,
    }

    onDateSelect = (newDate) => {
        console.log(newDate)
        console.log(typeof newDate.startDate)
        console.log(newDate.startDate)

        this.setState({
            ...this.state,
            fromSelectedDate: new Date(newDate.startDate),
            toDate: new Date(newDate.endDate)
        })
    }

    constructor(props) {
        super(props);

        this.calendarPickerView = null
        this.calendarView = <CalendarPickerView onConfirm={this.onDateSelect} onRef={ref => {this.calendarPickerView = ref}}/>
    }

    addToCompanyPayment(companyPaymentsDict, paymentObject) {
        if (companyPaymentsDict[paymentObject.payerId] === undefined) {
            companyPaymentsDict[paymentObject.payerId] = {}
        }

        if (companyPaymentsDict[paymentObject.payerId][paymentObject.product] === undefined) {
            companyPaymentsDict[paymentObject.payerId][paymentObject.product] = paymentObject.quantity
        } else {
            companyPaymentsDict[paymentObject.payerId][paymentObject.product] += paymentObject.quantity
        }

        return companyPaymentsDict
    }

    addStorePayment(storePaymentsDict, paymentObject) {
        if (storePaymentsDict[paymentObject.store] === undefined) {
            storePaymentsDict[paymentObject.store] = {}
        }

        if (storePaymentsDict[paymentObject.store][paymentObject.product] === undefined) {
            storePaymentsDict[paymentObject.store][paymentObject.product] = paymentObject.quantity
        } else {
            storePaymentsDict[paymentObject.store][paymentObject.product] += paymentObject.quantity
        }

        return storePaymentsDict
    }

    getDate = () => {
        if (this.state !== null && this.state.fromSelectedDate !== undefined && this.state.toDate !== undefined) {
            return this.state.fromSelectedDate.toDateString() + " - " +this.state.toDate.toDateString()
        }

        return (new Date()).toDateString()
    }

    onDateChange = (newDate) => {
        console.log(newDate)
    }

    generateTotalValue = () => {

        const {balances} = this.props
        let companyOrders = {}

        Object.keys(balances).map(function (key, index) {
            if (balances[key] !== undefined && balances[key].confirmed === true) {
            console.log(balances)
            if (companyOrders[balances[key].requesterId] === undefined) {
                companyOrders[balances[key].requesterId] = {}
            }

            if (companyOrders[balances[key].requesterId][balances[key].product] === undefined) {
                companyOrders[balances[key].requesterId][balances[key].product] = balances[key].quantity
            } else {
                companyOrders[balances[key].requesterId][balances[key].product] += balances[key].quantity
            }
        }
        })

        console.log(companyOrders)
        return companyOrders

    }

    generateCompanyPayments = (companyPayments, companyTotalPayments) => {

        const {classes, t, user} = this.props;

        let companyContent = []
        let companyOrders = this.generateTotalValue()

        Object.keys(companyPayments).map(function (key, index) {
            let regularQuantity = 0
            if (companyPayments[key]['regular'] !== undefined) {
                regularQuantity = companyPayments[key]['regular']
            }
            let premiumQuantity = 0
            if (companyPayments[key]['premium'] !== undefined) {
                premiumQuantity = companyPayments[key]['premium']
            }

            let regularUsage = 0

            if (companyTotalPayments[key]['regular'] !== undefined) {
                regularUsage = companyTotalPayments[key]['regular']
            }

            let premiumUsage = 0

            if (companyTotalPayments[key]['premium'] !== undefined) {
                premiumUsage = companyTotalPayments[key]['premium']
            }

            companyContent.push({
                id: key.replace('42UiZEA3zdXQFpUtulmWXs2YYAq2', 'payva').replace('08u8tm1RpXO2eUWllrCZaj7HWlo2', 'Ibari'),
                'regular': regularQuantity.toString() + ' | (Մնացորդ: ' + (companyOrders[key.replace('42UiZEA3zdXQFpUtulmWXs2YYAq2', 'gVcuHy1iGeWTRrL8Cik91n6wjuT2')]['regular'] - regularUsage) + ' )',
                'premium': premiumQuantity.toString() + ' | (Մնացորդ: ' + (companyOrders[key.replace('42UiZEA3zdXQFpUtulmWXs2YYAq2', 'gVcuHy1iGeWTRrL8Cik91n6wjuT2')]['premium'] - premiumUsage) + ' )',
                'super': 0,
                'diesel': 0
            })
        })

        return <GridContainer justify={"center"}>
            <GridItem xs={12} justify={"center"}>
                <Card>
                    <CardHeader color="info" className={classes.cardHeaderHover}>
                        <GridContainer>
                            <GridItem md={2}>
                                <h4>{t('date')}</h4>
                            </GridItem>
                            <GridItem md={2}>
                                <Button color={"transparent"} simple round onClick={() => this.calendarPickerView.showCalendarPickerView(this.onDateChange)}><FilterList/>{t("filter")}</Button>
                            </GridItem>
                            <GridItem md={8}>
                                <h4>{this.getDate()}</h4>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            hover
                            data={companyContent}
                            className="-striped -highlight"
                            defaultPageSize={4}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            columns={[
                                {
                                    Header: t("company"),
                                    accessor: "id"
                                },
                                {
                                    Header: t("Ռեգուլյար"),
                                    accessor: "regular"
                                },
                                {
                                    Header: t("Պրեմիում"),
                                    accessor: "premium"
                                },
                                {
                                    Header: t("Սուպեր"),
                                    accessor: "super"
                                },
                                {
                                    Header: t("Դիզել"),
                                    accessor: "diesel"
                                },
                                {
                                    Header: t(""),
                                    accessor: "noneaccessor",
                                    filterable: false,
                                    sortable: false
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    }

    generateStoreTable = (storePaymentsList) => {

        const {classes, t, user} = this.props;

        let storeContent = []

        Object.keys(storePaymentsList).map(function (key, index) {
            let regularQuantity = 0
            if (storePaymentsList[key]['regular'] !== undefined) {
                regularQuantity = storePaymentsList[key]['regular']
            }
            let premiumQuantity = 0
            if (storePaymentsList[key]['premium'] !== undefined) {
                premiumQuantity = storePaymentsList[key]['premium']
            }

            storeContent.push({
                id: user.stores[key],
                'regular': regularQuantity,
                'premium': premiumQuantity,
                'super': 0,
                'diesel': 0
            })
        })

        return <GridContainer justify={"center"}>
            <GridItem xs={12} justify={"center"}>
                <Card>
                    <CardHeader color="info" className={classes.cardHeaderHover}>
                        <GridContainer>
                            <GridItem md={2}>
                                <h4>{t('date')}</h4>
                            </GridItem>
                            <GridItem md={2}>
                                <Button color={"transparent"} simple round onClick={() => this.calendarPickerView.showCalendarPickerView(this.onDateChange)}><FilterList/>{t("filter")}</Button>
                            </GridItem>
                            <GridItem md={8}>
                                <h4>{this.getDate()}</h4>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            hover
                            data={storeContent}
                            className="-striped -highlight"
                            defaultPageSize={14}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            columns={[
                                {
                                    Header: t("store"),
                                    accessor: "id"
                                },
                                {
                                    Header: t("Ռեգուլյար"),
                                    accessor: "regular"
                                },
                                {
                                    Header: t("Պրեմիում"),
                                    accessor: "premium"
                                },
                                {
                                    Header: t("Սուպեր"),
                                    accessor: "super"
                                },
                                {
                                    Header: t("Դիզել"),
                                    accessor: "diesel"
                                },
                                {
                                    Header: t(""),
                                    accessor: "noneaccessor",
                                    filterable: false,
                                    sortable: false
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    }

    parsePayments = (paymentsList) => {
        if (paymentsList !== undefined && paymentsList !== null) {
            let companyPayments = {}
            let companyTotalPayments = {}
            let storePayments = {}
            Object.keys(paymentsList).map((key, index) => {
                if (this.state !== null && this.state.toDate !== undefined && this.state.toDate !== null && paymentsList[key].updated.toDate() > this.state.toDate) {return;}
                companyTotalPayments = this.addToCompanyPayment(companyTotalPayments, paymentsList[key])

                if (this.state !== null && this.state.fromSelectedDate !== undefined && this.state.fromSelectedDate !== null && paymentsList[key].updated.toDate() < this.state.fromSelectedDate) {return;}
                storePayments = this.addStorePayment(storePayments, paymentsList[key])
                companyPayments = this.addToCompanyPayment(companyPayments, paymentsList[key])
            })

            return [this.generateCompanyPayments(companyPayments, companyTotalPayments), this.generateStoreTable(storePayments)]
        } else {
            return <p>Empty</p>
        }
    }

    render() {
        return <div>
            {this.parsePayments(this.state.payments)}
            {this.calendarView}
        </div>
    };
}

const enhance = compose(
    withTranslation(),
    withFirestore,
    withStyles(dashboardViewStyle),

    firestoreConnect((props) => [
        {
            collection: 'payments',
            where: [
                ['payeeId', '==', props.firebase.auth().currentUser.uid],
            ],
            orderBy: ['updated', 'desc']
        },
        {
            collection: 'requests',
            doc: 'product',
            subcollections: [
                {
                    collection: 'petrol',
                    where: [
                        ['requestorId', '==', props.firebase.auth().currentUser.uid]
                    ],
                    orderBy: ['updated', 'desc']
                }
            ],
            storeAs: "requests",
        },
    ]),

    connect(({firestore: {data, errors}}, props) => ({
        payments: data.payments,
        balances: data.requests
    })),

    spinnerWhileLoading(['user', 'payments', 'balances'])
);


export default enhance(ReportView);