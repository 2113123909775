// Redux Modules
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { getFirebase } from 'react-redux-firebase'

// Reducer
import makeRootReducer from './reducers/makeReducer'

export default (initialState = {}) => {

    // ======================================================
    // Store Enhancers
    // ======================================================
    const enhancers = [];

    // ======================================================
    // Middleware Configuration
    // ======================================================
    const middleware = [
        thunk.withExtraArgument(getFirebase)
        // This is where you add other middleware like redux-observable
    ];

    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    const store = createStore(
        makeRootReducer(),
        initialState,
        compose(
            applyMiddleware(...middleware),
            ...enhancers
        )
    );

    store.asyncReducers = {};

    if (module.hot) {
        module.hot.accept('./reducers/makeReducer', () => {
            const reducers = require('./reducers/makeReducer').default;
            store.replaceReducer(reducers(store.asyncReducers))
        })
    }

    return store
};
