import {HOME} from "../../config/routePaths";
import React from "react";
import { Route } from "react-router-dom";
import Countdown from 'react-countdown-now';
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";

const style = ({
    centerToScreen: {
        height: "200px",
        width: "400px",
        position: "fixed",
        top: "50%",
        left: "50%",
        marginTop: "-100px",
        marginLeft: "-200px"
    }
});

class Maintenance extends React.Component {
    render() {
        const {t, classes} = this.props;

        return (
            <div className={classes.centerToScreen}>
                <h3>{t("title")}</h3>
                <h2>{t("header1")}</h2>
                <h1><Countdown date={1572420514531 + 50000000} /></h1>
            </div>
        )
    }
}


export default {
    route: <Route path={HOME} component={withStyles(style)(withTranslation()(Maintenance))} />
}