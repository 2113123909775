import React, { Suspense } from 'react';

import "./assets/scss/payva-dashboard.scss"

import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

// Pages
import Maintenance from "./pages/Maintenance";
import AuthPage from "./pages/Auth";
import DashboardPage from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";

const hist = createBrowserHistory();

function App() {
  return (
      <Suspense fallback="loading">
          <Router history={hist}>
              {/*{Maintenance.route}*/}
              {/*{ DashboardPage.default }*/}
              { ErrorPage.route }
              { AuthPage.route }
              { DashboardPage.defaultRoute }
              { DashboardPage.providerRoute }
              { DashboardPage.merchantRoute }
              { DashboardPage.companyRoute }
              { DashboardPage.storeRoute }
              { DashboardPage.employeeRoute }
              { DashboardPage.managementRoute }
          </Router>
      </Suspense>
  );
}

export default App;
