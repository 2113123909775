import React from "react";

// React Table
import ReactTable from "react-table";

// Paths
import { NOT_FOUND } from "../../../config/routePaths";

// Internal Components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";

//Style
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardViewStyle from "../style/dashboardViewStyle"

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterList from "@material-ui/icons/FilterList";

// Date Time Filter
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Network
import { compose } from 'redux'
import {connect} from "react-redux";
import {I18nContext, withTranslation} from "react-i18next";
import { withFirestore } from "react-redux-firebase";
import CardHeader from "../../../components/Card/CardHeader";

// Sub Views
import DashboardCardHeaderView from "./DashboardCardHeaderView";
import CalendarPickerView from "./CalendarPickerView";

// Components
import Button from "../../../components/CustomButtons/Button";
import {spinnerWhileLoading} from "../../../network/auth";

class ProviderDashboardView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spinner: true,
            paymentsList: [],
            dateFilter: null,
            storeFilter: null,
            productFilter: ""
        };

        this.headerView = null;
        this.calendarPickerView = null;
        this.changeProduct = this.changeProduct.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onDateFilterPress = this.onDateFilterPress.bind(this);
    };

    componentDidMount() {
        this.headerView && this.headerView.changeProduct("");
        this.getTableData()
    }

    updatePaymentsList(paymentsListSnapshot) {
        if (paymentsListSnapshot.empty) {
            this.setState({
                spinner: false,
                paymentsList: null,
                dateFilter: null
            })
        } else {
            this.setState({
                spinner: false,
                paymentsList: paymentsListSnapshot,
                dateFilter: null
            });
        }
    }

    getTableData() {
        const {firestore, firebase} = this.props;

        const providerDatabaseRef = firestore.collection("payments")
            .orderBy('updated', 'desc')
            .where("payeeId", "==", firebase.auth().currentUser.uid);

        providerDatabaseRef.get()
            .then(snapshot => {
                this.updatePaymentsList(snapshot);
            })
            .catch(err => {
                console.log('Error getting documents', err);
                this.setState({spinner: false});
            });
    }

    formatDate(date) {
        const {t} = this.props;

        const monthNames = [
            t("jan"),
            t("feb"),
            t("mar"),
            t("apr"),
            t("may"),
            t("jun"),
            t("jul"),
            t("aug"),
            t("sep"),
            t("oct"),
            t("nov"),
            t("dec")
        ];

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }

    formatTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return hours + ':' + minutes;
    }

    getTableContent() {
        const content = [];

        this.state.paymentsList.forEach(doc => {
            const currentData = doc.data();
            content.push({
                id: doc.id,
                date: this.formatDate(currentData.updated.toDate()),
                time: this.formatTime(currentData.updated.toDate()),
                product: this.props.t(currentData.product),
                quantity: currentData.quantity,
                store: this.props.user.stores[currentData.store],
                company: currentData.payerName
            });
        });

        return content
    }

    getStoreOptions() {
        const {user} = this.props;

        const finalData = [];

        if (user !== undefined)
            Object.keys(user.stores).map(function (key, index) {
                finalData.push(<option value={user.stores[key]}>{user.stores[key]}</option>)
            });

        return finalData;
    }

    changeProduct(value) {
        // this.setState({
        //     ...this.state,
        //     productFilter: value.toLowerCase()});

        this.headerView.changeProduct(value.toLowerCase());
        // console.log(this.headerView);
    }

    changeStore(value) {
        this.headerView.changeStore(value);
    }

    onConfirm(dateRange) {
        console.log(this.headerView);
        this.headerView.updateDatePeriod(dateRange)
    }

    getTable() {

        const {classes, t} = this.props;

        const content = this.getTableContent();

        return (
            <div>
                <GridContainer justify={"center"}>
                    <GridItem xs={12} ustify={"center"}>
                        <Card>
                            <CardHeader color="info" className={classes.cardHeaderHover}>
                                <DashboardCardHeaderView paymentsList={this.state.paymentsList} storesList={this.props.user.stores} onRef={ref => (this.headerView = ref)}/>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    hover
                                    data={content}
                                    filterable
                                    columns={[
                                        {
                                            Header: t("date"),
                                            accessor: "date",
                                            Filter: ({filter, onChange}) => <Button color={"transparent"} simple round onClick={() => this.onDateFilterPress(onChange)}><FilterList/>{t("filter")}</Button>,
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "" || filter.value.startDate === null || filter.value.endDate === null || filter.value.startDate === undefined || filter.value.endDate === undefined) {
                                                    return true
                                                } else {
                                                    const currentDate = new Date(row[filter.id]);
                                                    const fromDate = new Date(filter.value.startDate);
                                                    const toDate = new Date(filter.value.endDate);

                                                    return currentDate >= fromDate && currentDate <= toDate
                                                }
                                            },
                                        },
                                        {
                                            Header: t("time"),
                                            accessor: "time",
                                            sortable: false,
                                            filterable: false
                                        },
                                        {
                                            Header: t("product"),
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => {onChange(event.target.value); this.changeProduct(event.target.value)}}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : ""}
                                                >
                                                    <option value="">{t("all")}</option>
                                                    <option value={t("regular")}>{t("regular")}</option>
                                                    <option value={t("premium")}>{t("premium")}</option>
                                                    <option value={t("super")}>{t("super")}</option>
                                                    <option value={t("diesel")}>{t("diesel")}</option>
                                                </select>,
                                            accessor: "product"
                                        },
                                        {
                                            Header: t("quantity"),
                                            accessor: "quantity",
                                            filterable: false
                                        },
                                        {
                                            Header: t("store"),
                                            Filter: ({ filter, onChange }) =>
                                                <select
                                                    onChange={event => {onChange(event.target.value); this.changeStore(event.target.value)}}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : ""}
                                                >
                                                    <option value="">{t("all")}</option>
                                                    {this.getStoreOptions()}
                                                </select>,
                                            accessor: "store"
                                        },
                                        {
                                            Header: t("company"),
                                            sortable: false,
                                            accessor: "company"
                                        }
                                                            // {
                                                            //     Header: "",
                                                            //     accessor: "nothing",
                                                            //     sortable: false,
                                                            //     filterable: false
                                                            // }
                                    ]}
                                    defaultPageSize={this.state.paymentsList.size + 3}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    getTableOfUsage() {

        const {classes, t } = this.props;

        if (this.state.spinner) {
            return <div className={classes.centerOfTheScreen}><CircularProgress/></div>
        } else {
            if (this.state.paymentsList == null || this.state.paymentsList.length === 0) {
                return <div className={classes.centerOfTheScreen}>{t("no_payment_found")}</div>
            } else {
                return this.getTable();
            }
        }
    }

    onDateFilterPress(onChangeMethod) {
        this.calendarPickerView.showCalendarPickerView(onChangeMethod);
    }

    render() {
        if (this.props.user.business.provider === true) {
            return <div>
                {this.getTableOfUsage()}
                <CalendarPickerView onConfirm={this.onConfirm} onRef={ref => (this.calendarPickerView = ref)}/>
            </div>
        } else {
            window.location.assign(NOT_FOUND)
        }
    };
}

const enhance = compose(
    withTranslation(),
    withFirestore,
    connect((state, props) => ({
        user: state.firestore.ordered.users[0]
    })),
    withStyles(dashboardViewStyle),
    spinnerWhileLoading(['user'])
);


export default enhance(ProviderDashboardView);