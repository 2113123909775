import React from "react";

import {NOT_FOUND} from "../../../config/routePaths";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardViewStyle from "../style/dashboardViewStyle";
import {spinnerWhileLoading} from "../../../network/auth";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import CardFooter from "../../../components/Card/CardFooter";
import CardIcon from "../../../components/Card/CardIcon";
import Petrol from "../../../icons/petrol";
import Gym from "../../../icons/gym";
import Taxi from "../../../icons/taxi";
import Food from "../../../icons/food";
import Report from "../../../icons/report";
import ReactTable from "react-table";

class ProfileView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: []
        };

        this.syncPayments = this.syncPayments.bind(this);
        this.getPayments = this.getPayments.bind(this);
    }

    componentDidMount() {
        this.syncPayments()
    }

    syncPayments() {
        console.log("Executing/Syncing Data");
        const {firebase} = this.props;

        // const companyAllData = firebase.database().ref("balances/" + firebase.auth().currentUser.uid);
        //
        // companyAllData.on('value', snapshot => {
        //     console.log(snapshot.val());
        //     this.updateBalance(snapshot.val())
        // });
    }

    updateBalance(snapshotData) {
        if (!snapshotData.empty) {
            let currentPayments = {};
            if (snapshotData.payments !== undefined && !snapshotData.payments.empty) {
                currentPayments = snapshotData.payments
            }
            this.setState({
                ...this.state,
                foodTotalSpends: snapshotData.totalUsed,
                users: snapshotData.user,
                payments: currentPayments
            })
        }
    }

    getPayments() {
        const content = [];
        Object.entries(this.state.payments).forEach(([key, value]) => {
            console.log(key, value);

            let user = "N/A";
            let amount = 0;

            Object.entries(value).forEach(([subKey, subValue]) => {
                user = subKey;
                amount = subValue
            });

            content.push({
                date: key.toString().replace(/_/g, "/").replace(/-/g, ":"),
                name: this.state.users[user].name,
                amount: amount.toString() + " ֏",
                store: "Արտ Լանչ - Կոկա Կոլա մասնաճյուղ",
                company: "Արտ Լանչ"
            })
        });
        return content
        // if (allKeys.length > 0) {
        //     const content = [];
        //     for (let key in allKeys) {
        //         console.log(this.state.payments[key]);
        //         console.log(key);
        //     }
        //     // this.state.payments.forEach(paymentObject => {
        //     //
        //     //         console.log(paymentObject);
        //     //     //     content.push({
        //     //     //
        //     //     //         date: key
        //     //     //         // id: paymentObject.
        //     //     //     })
        //     //     // }
        //     // });
        //     return content
        // } else {
        //     return []
        // }
    }

    render() {
        const { t, classes } = this.props;
        console.log(this.state.payments);
        if (this.props.user.business.company === true) {
            return <div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <Petrol/>
                                </CardIcon>
                                <p className={classes.cardCategory}>{t("used_limits")}</p>
                                <h3 className={classes.cardTitle}>
                                    0
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    {/*<a href="#pablo" onClick={e => e.preventDefault()}>*/}
                                    {t("deactivated")}
                                    {/*</a>*/}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Food />
                                </CardIcon>
                                <p className={classes.cardCategory}>{t("used_limits")}</p>
                                <h3 className={classes.cardTitle}>{this.state.foodTotalSpends}<small>֏</small></h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    {/*<DateRange />*/}
                                    {t("active")}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Taxi />
                                </CardIcon>
                                <p className={classes.cardCategory}>{t("used_limits")}</p>
                                <h3 className={classes.cardTitle}>0</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    {/*<DateRange />*/}
                                    {t("deactivated")}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={3}>
                        <Card>
                            <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Gym />
                                </CardIcon>
                                <p className={classes.cardCategory}>{t("used_limits")}</p>
                                <h3 className={classes.cardTitle}>0</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    {/*<Update />*/}
                                    {t("deactivated")}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} lg={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <Report />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>{t("detailed")}</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={this.getPayments()}
                                    filterable
                                    columns={[
                                        {
                                            Header: t("date"),
                                            accessor: "date"
                                        },
                                        {
                                            Header: t("amount"),
                                            accessor: "amount"
                                        },
                                        {
                                            Header: t("store"),
                                            accessor: "store"
                                        },
                                        {
                                            Header: t("company"),
                                            accessor: "company"
                                        },
                                        {
                                            Header: t("name"),
                                            accessor: "name",
                                            sortable: false,
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        } else {
            window.location.assign(NOT_FOUND)
        }
    };
}


const enhance = compose(
    withTranslation(),
    withFirestore,
    connect((state, props) => ({
        user: state.firestore.ordered.users[0]
    })),
    withStyles(dashboardViewStyle),
    spinnerWhileLoading(['user'])
);


export default enhance(ProfileView);