import actions from '../actions/actionTypes';

const initState = {
    authError: null
};

const authReducer = (state = initState, action) => {
    switch(action.type){
        case actions.LOGIN_ERROR:
            console.log('login error');
            return {
                ...state,
                authError: 'Login failed'
            };

        case actions.LOGIN_SUCCESS:
            console.log('login success');
            return {
                ...state,
                authError: null
            };

        case actions.SIGN_OUT_SUCCESS:
            console.log('signout success');
            return state;

        case actions.SIGN_UP_SUCCESS:
            console.log('signup success');
            return {
                ...state,
                authError: null
            };

        case actions.SIGN_UP_ERROR:
            console.log('signup error');
            return {
                ...state,
                authError: action.err.message
            };

        default:
            return state
    }
};

export default authReducer;