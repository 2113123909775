import React from "react";
import { Route, Redirect } from "react-router-dom";

// Authentication Security
import {UserIsAuthenticated, UserIsNotAuthenticated} from "../../network/auth";

import {
    DASHBOARD,
    SIGN_OUT,
    HOME,
    MERCHANT,
    COMPANY,
    PROVIDER,
    STORE,
    MANAGEMENT,
    EMPLOYEE
} from "../../config/routePaths";
import Dashboard from "../Dashboard/pages";
import SignOut from "../Auth/views/SignOut";

// Sync route definition
export default {
    defaultRoute: <Route exact path={HOME} component={UserIsAuthenticated(Dashboard)} />,
    merchantRoute: <Route path={MERCHANT} component={UserIsAuthenticated(Dashboard)} />,
    providerRoute: <Route path={PROVIDER} component={UserIsAuthenticated(Dashboard)} />,
    companyRoute: <Route path={COMPANY} component={UserIsAuthenticated(Dashboard)} />,
    employeeRoute: <Route path={EMPLOYEE} component={UserIsAuthenticated(Dashboard)} />,
    storeRoute: <Route path={STORE} component={UserIsAuthenticated(Dashboard)} />,
    logOutRoute: <Route path={SIGN_OUT} component={SignOut} />,
    managementRoute: <Route path={MANAGEMENT} component={UserIsAuthenticated(Dashboard)} />,
    // default: <Route exact from={HOME} component={UserIsAuthenticated(Dashboard)}/>
}