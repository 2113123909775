const content = {
    translation: {
        // Common words
        "settings": "Կարգավորումներ",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "may": "May",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dec",
        // "jan": "Հուն",
        // "feb": "Փետ",
        // "mar": "Մար",
        // "apr": "Ապր",
        // "may": "Մայ",
        // "jun": "Հուն",
        // "jul": "Հուլ",
        // "aug": "Օգ",
        // "sep": "Սեպ",
        // "oct": "Հոկ",
        // "nov": "Նոյ",
        // "dec": "Դեկ",

        "l": "Լ",
        "amd": "Դրամ",
        "premium": "Պրեմիում",
        "premium_price": "Պրեմիում Գին",
        "regular": "Ռեգուլյար",
        "regular_price": "Ռեգուլյար Գին",
        "super": "Սուպեր",
        "super_price": "Սուպեր Գին",
        "diesel": "Դիզել",
        "diesel_price": "Դիզել Գին",

        // "premium": "Premium",
        // "regular": "Regular",
        // "super": "Super",
        // "diesel": "Diesel",

        "all": "Բոլորը",
        "present": "Ներկայ",
        "from": "Սկսած",
        "to": "Վերջացած",
        "select": "Ընտրել",
        "cancel": "Չեղարկել",
        "ok": "Հաստատել",
        "reset": "Վերակայել",
        "filter": "Ֆիլտր",
        "total": "Ընդհանուր",
        "price": "Գինը",
        "updated": "Թարմացվել է",
        "id": "Նույնականացում",
        "closed": "Փակված",
        "active": "Ակտիվ",
        "status": "Կարգավիճակը",

        "company": "Ընկերություն",
        "balance": "Մնացորդ",

        "deactivated": "Անջատված",
        "used_limits": "Օգտագործված",
        "detailed": "Վճարումների մանրամասնություն",
        "name": "Անուն",
        "amount": "Գումար",
        "financial_rules": "Ֆինանսական կանոններ",
        "staff": "Աշխատակազմ",
        "rules_list": "Կանոնների ցուցակ",
        "rule_name": "Անվանումը",
        "type": "Տիպ",
        "due_date": "Վերջնաժամկետ",
        "assigned_people": "Կցված անձնակազմ",
        "lunch": "Լանչ",
        "staff_list": "Աշխատակազմի ցուցակ",

        "hr": "ՄՌ",
        "department": "Վարչություն",
        "change": "Փոխել",

        "not_allowed_message": "Գործողությունը թույլատրված չէ տվյալ օգտատերի համար: Հարցերի դեպքում զանգահարել +374 44 729 828",


        // Abbreviations
        "settings_abbreviation": "Ս",

        // NavBar Content
        "home": "Գլխավոր",

        "detail": "Տեսնել",
        "success_canceled": "Հայտը հաջողությամբ չեղարկված է:",
        "success_confirmed": "Հայտը հաջողությամբ հաստատված է:",
        "close": "Փակել",
        "cancel_order": "Չեղարկել հայտը",
        "confirm_order": "Հաստատել հայտը",
        "order_from": "Հայտի կոդ: #",

        //Login Page
        "log_in": "Մուտք գործել",
        "logout": "Դուրս Գալ",
        "email_hint": "Էլ. Փոստ ...",
        "password_hint": "Գաղտնաբառ ...",
        "login_button": "Մուտք",

        // Dashboard SideBar
        "edit_profile": "Խմբագրել պրոֆիլը",
        "edit_profile_abbreviation": "Խպ",
        "my_profile": "Իմ էջը",
        "my_profile_abbreviation": "պ",

        "dashboard": "Գրասեղան",
        "requests": "Հայցեր",
        "reports": "Հաշվետվություն",
        "commingSoon": "Հասանելի շուտով",
        "check_requests": "Ստուգեք պահանջները",
        "request_footer_text": "Այս տարվա ընդհանուր դիմումների քանակը",

        // ---------- Pages -------------

        // Maintenance
        "title": "Վեբ կայքի սպասարկում",
        "header1": "Մենք շուտով կվերադառնանք",

        // Dashboard Provider
        "no_requests_found": "Ոչ մի ակտիվ հարցում չի գտնվել",
        "no_payment_found": "Ոչ մի վճար չի գտնվել",
        "date": "Ամսաթիվ",
        "time": "Ժամանակը",
        "product": "Ապրանք",
        "quantity": "Քանակը",
        "store": "Խանութ",
        "pick_time_period": "Ընտրեք ժամանակաշրջանը",
        "dailyPrice": "Անհատի գին",
        "submit": "Հաստատել"
    }
};

export default content;