import {compose} from "redux";
import {withTranslation} from "react-i18next";

// Component
import ErrorPage from "./ErrorPage";

const enhance = compose(
    withTranslation()
);

export default enhance(ErrorPage);