import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const Person = (props) => (
    <SvgIcon viewBox='0 0 87.5 104.7' {...props}>
        <g>
            <rect y="83.9" width="3.9" height="20.7"/>
            <rect x="29.6" y="56.4" width="30.2" height="3.4"/>
            <rect x="7.9" y="101.2" width="35.2" height="3.4"/>
            <rect x="47.3" y="101.2" width="33.1" height="3.4"/>
            <rect x="83.6" y="83.9" width="3.9" height="20.7"/>
            <g>
                <path d="M34.4,45.4l3.7,1.6l3.5,0.7l-0.8,3.6l-4.2-0.7l-4-1.7l-4.9-3.3L24.2,42l-3.3-4.9l-1.6-3.9L18.6,29l3.6-0.7l0.7,3.5l1.7,3.8l2.7,4l3.2,3.2L34.4,45.4z"/>
                <path d="M54.9,5.9l-3.7-1.6l-3.5-0.7L48.4,0l4.2,0.7l4,1.7l4.9,3.3l3.7,3.7l3.3,4.9l1.6,3.9l0.7,4.2l-3.6,0.7l-0.7-3.5l-1.7-3.8l-2.7-4l-3.2-3.2L54.9,5.9z"/>
                <path d="M64.8,35.5l1.6-3.7l0.7-3.5l3.6,0.8L70,33.2l-1.7,4l-3.3,4.9l-3.7,3.7L56.5,49l-3.9,1.6l-4.2,0.7l-0.7-3.6l3.5-0.7l3.8-1.7l4-2.7l3.2-3.2L64.8,35.5z"/>
                <path d="M24.5,15.9l-1.6,3.7l-0.7,3.5l-3.6-0.8l0.7-4.2l1.7-4l3.3-4.9l3.7-3.7l4.9-3.3l3.9-1.6L40.9,0l0.7,3.6l-3.5,0.7L34.4,6l-4,2.7l-3.2,3.2L24.5,15.9z"/>
            </g>
            <path d="M71.6,62.7l-3.7-1.6l-3.5-0.7l0.8-3.6l4.2,0.7l4,1.7l4.9,3.3l3.7,3.7l3.3,4.9l1.6,3.9l0.7,4.2l-3.6,0.7l-0.7-3.5l-1.7-3.8l-2.7-4l-3.2-3.2L71.6,62.7z"/>
            <path d="M6.4,72.7l-1.6,3.7L4,80l-3.6-0.8L1.2,75l1.7-4l3.3-4.9l3.7-3.7l4.9-3.3l3.9-1.6l4.2-0.7l0.7,3.6l-3.5,0.7l-3.8,1.7l-4,2.7L9,68.7L6.4,72.7z"/>
        </g>
    </SvgIcon>
);

export default Person;