import React from "react";

import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardViewStyle from "../style/dashboardViewStyle";
import {spinnerWhileLoading} from "../../../network/auth";

// Icons
import Petrol from "../../../icons/petrol";

//Components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardBody from "../../../components/Card/CardBody";
import CardIcon from "../../../components/Card/CardIcon";
import Card from "../../../components/Card/Card";
import Table from "../../../components/Table/Table";
import CardHeader from "../../../components/Card/CardHeader";
import Button from "../../../components/CustomButtons/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {withHandlers} from "recompose";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import {th} from "react-date-range/dist/locale";

class RequestsDashboardView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: false,
            alert: null,
            priceAlert: null
        };

        this.prices = {};

        this.productPrice = 0;

        if (props.prices !== null || props.prices !== undefined) {
            this.prices = {...props.prices[0]}
        } else {
            this.prices = {
                regular: "0",
                premium: "0",
                super: "0",
                diesel: "0",
                updated: Date()
            };
        }

        this.priceConfirmAlert = this.priceConfirmAlert.bind(this);
        this.onRegularChange = this.onRegularChange.bind(this);
        this.onPremiumChange = this.onPremiumChange.bind(this);
        this.onSuperChange = this.onSuperChange.bind(this);
        this.onDieselChange = this.onDieselChange.bind(this);

        this.onSubmitPrices = this.onSubmitPrices.bind(this);
        this.onEditPrices = this.onEditPrices.bind(this);

        this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);

        this.onProductPriceChange = this.onProductPriceChange.bind(this);
    }

    hideAlert() {
        this.setState({
            ...this.state,
            alert: null,
            priceAlert: null
        })
    };

    confirm(productId) {
        this.props.confirm(productId);
        this.setState({
            ...this.state,
            alert: <SweetAlert
                success
                style={{display: "block", marginTop: "100px"}}
                title="Հաստատում"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
            >
                {this.props.t("success_confirmed")}
            </SweetAlert>
        });
    };

    cancel(productId) {
        this.props.decline(productId);
        this.setState({
            ...this.state,
            alert: <SweetAlert
                danger
                style={{ display: "block", marginTop: "100px" }}
                title="Չեղարկվեց"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
            >
                {this.props.t("success_canceled")}
            </SweetAlert>
        });
    };

    getStatusUpdateButton(requestId, amount, total, companyName, address, taxCode, director) {
        const {t} = this.props;

        return <Button onClick={() => this.warningWithConfirmAndCancelMessage(requestId, total, amount, companyName, address, taxCode, director)} color={"success"}>{t("detail")}</Button>

    }

    warningWithConfirmAndCancelMessage(requestId, total, amount, companyName, address, taxCode, director) {
        const orderNumber = this.props.t("order_from") + " " + requestId.toString().substring(0,5);
        console.log(requestId);
        console.log("ID: is");
        console.log(orderNumber);

        this.setState({
                ...this.state,
                alert: <SweetAlert
                    showCloseButton={true}
                    info
                    style={{display: "block", margin: "20px"}}
                    title={orderNumber}
                    closeOnClickOutside={true}
                    onCancel={() => this.hideAlert()}
                    showConfirm={false}
                >
                    Հաստատելով հայտը դուք թույլատրում եք օգտվել նշված բալանսից:
                    <Table
                        tableData={[
                            ["Քանակ:", total],
                            ["Գին/Հաշիվ:", amount],
                            ["Անվանում:", companyName],
                            ["Հասցե:", address],
                            ["ՀՎՀՀ:", taxCode],
                            ["Տնօրեն:", director]
                        ]}
                        coloredColls={[4]}
                        colorsColls={["primary"]}
                    />
                    {/*<Button onClick={() => this.cancel(requestId)} color={"danger"}>{this.props.t("cancel_order")}</Button>*/}
                    <Button onClick={() => this.confirm(requestId)} color={"success"}>{this.props.t("confirm_order")}</Button>
                </SweetAlert>
            }
        );
    };

    onRegularChange = e => {
        this.prices.regular = e.target.value.toString();

        console.log(this.prices);
    };

    onPremiumChange = e => {
        this.prices.premium = e.target.value.toString();

        console.log(this.prices);
    };


    onSuperChange = e => {
        this.prices.super = e.target.value.toString();

        console.log(this.prices);
    };

    onDieselChange = e => {
        this.prices.diesel = e.target.value.toString();

        console.log(this.prices);
    };

    onProductPriceChange = e => {
        this.productPrice = e.target.value.toString();

        console.log(this.productPrice);
    };


    onSubmitPrices() {
        this.props.addPrices({
            ...this.prices,
            updated: Date()
        });

        this.setState({
            ...this.state,
            editable: false
        })
    };

    onEditPrices() {
        this.setState({
            ...this.state,
            editable: true
        })
    }

    getButton() {
        if (this.state.editable) {
            return <Button onClick={this.onSubmitPrices}>{this.props.t("submit")}</Button>
        } else {
            return <Button onClick={this.onEditPrices}>{this.props.t("change")}</Button>
        }
    }

    parsePriceTableData() {
        const {t, prices} = this.props;
        const tableData = [];
        if (prices !== null && prices !== undefined) {
            if (!this.state.editable) {
                Object.values(prices).map((value, i) => {
                    tableData.push([
                        value.regular,
                        value.premium,
                        value.super,
                        value.diesel
                    ]);
                });
            } else {
                tableData.push([
                    <CustomInput
                        labelText={t("regular_price")}
                        id="regularPrice"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: this.onRegularChange
                        }}
                    />,
                    <CustomInput
                        labelText={t("premium_price")}
                        id="premiumPrice"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: this.onPremiumChange
                        }}
                    />,

                    <CustomInput
                        labelText={t("super_price")}
                        id="superPrice"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: this.onSuperChange
                        }}
                    />,
                    <CustomInput
                        labelText={t("diesel_price")}
                        id="dieselPrice"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: this.onDieselChange
                        }}
                    />,
                ]);
            }
        }
        //
        // tableData.push([
        //
        // ]);

        // const tableData = [[prices.regular, prices.premium, prices.super, prices.diesel]];
        console.log(prices);

        return tableData
    }

    confirmPriceButton(requisite, id, product, quantity) {
        return <Button color={"success"} onClick={() => this.priceConfirmAlert(requisite, id, product, quantity)}>{this.props.t("detail")}</Button>
    }

    getStatusText(active, confirmed, declined, priceConfirmed ) {
        if (active) {
            if (priceConfirmed === false) {
                return "Գնի Հայտարարում"
            }
            if (confirmed === false && declined === false) {
                return "Հայտի հաստատում"
            }

            if (declined === true) {
                return "Մերժված"
            }

            if (confirmed === true) {
                return "Օգտագործում"
            }
        }

        if (declined === true) {
            return "Մերժված"
        }

        return "Փակված"
    }

    parseTableData() {
        const {t, requests} = this.props;
        const tableData = [];
        if (requests !== null || requests) {
            Object.values(requests).map((value, i) => {
                const requestId = value.id.toString();
                if (value.active === true) {
                    tableData.unshift([
                        // value.
                        value.requesterName,
                        t(value.product),
                        value.quantity.toString() + " " + t(value.quantityUnit),
                        value.quantityRemaining.toString() + " " + t(value.quantityUnit),
                        value.price.toString() + " " + t(value.priceUnit),
                        (value.price * value.quantity).toString() + " " + t(value.priceUnit),
                        value.updated.toDate().toString(),
                        this.getStatusText(value.active, value.confirmed, value.decline, value.priceConfirmed),
                        value.priceConfirmed? null: this.confirmPriceButton(value.requisite, requestId, t(value.product), value.quantity.toString() + " " + t(value.quantityUnit)),
                        !value.confirmed && value.priceConfirmed && value.priceFinal? this.getStatusUpdateButton(requestId, (value.price * value.quantity).toString() + " " + t(value.priceUnit), value.quantity.toString() + " " + t(value.quantityUnit) + " " + t(value.product), value.requisite.companyName, value.requisite.address, value.requisite.taxCode, value.requisite.director): null
                    ]);
                } else {
                    tableData.push([
                        value.requesterName,
                        t(value.product),
                        value.quantity.toString() + " " + t(value.quantityUnit),
                        value.quantityRemaining.toString() + " " + t(value.quantityUnit),
                        value.price.toString() + " " + t(value.priceUnit),
                        (value.price * value.quantity).toString() + " " + t(value.priceUnit),
                        value.updated.toDate().toString(),
                        this.getStatusText(value.active, value.confirmed, value.decline, value.priceConfirmed),
                        // value.priceConfirmed? null: this.confirmPriceButton(requestId, t(value.product), value.quantity.toString() + " " + t(value.quantityUnit)),
                        value.confirmed || !value.active? null: this.getStatusUpdateButton(requestId, (value.price * value.quantity).toString() + " " + t(value.priceUnit), value.quantity.toString() + " " + t(value.quantityUnit) + " " + t(value.product), value.requisite.companyName, value.requisite.address, value.requisite.taxCode, value.requisite.director)
                    ]);
                }
            });
        }

        return tableData
    }

    priceConfirmAlert(requisite, requestId, product, amount) {
        this.productPrice = 0;
        const orderNumber = this.props.t("order_from") + " " + requestId.toString().substring(0,5);
        if (this.state.priceAlert == null) {
            this.setState({
                ...this.state,
                priceAlert: <SweetAlert
                    showCloseButton={true}
                    info
                    title={orderNumber}
                    style={{display: "block", margin: "20px"}}
                    confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    onConfirm={() => {this.props.setProductPrice(requestId, this.productPrice); this.hideAlert()}}
                    onCancel={() => this.hideAlert()}
                    confirmBtnText={"Ուղարկել գինը"}
                    cancelBtnText={"Փակել"}
                    showCancel
                >
                    Հայտատարեք գին
                    <br/>
                    <Table
                        tableData={[
                            ["Քանակ:", amount],
                            ["Գին/Հաշիվ:", product],
                            ["Կազմակերպություն:", requisite.companyName],
                            ["ՀՎՀՀ:", requisite.taxCode]
                        ]}
                        coloredColls={[4]}
                        colorsColls={["primary"]}
                    />


                    <CustomInput
                        labelText={"Գին"}
                        id="priceForProduct"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: this.onProductPriceChange
                        }}
                    />,
                </SweetAlert>
            })
        }
    }

    render() {
        const {t} = this.props;
        return <div>
            <GridContainer justify={"center"}>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <Petrol/>
                            </CardIcon>
                            <h2>{t('dailyPrice')}</h2>
                            {/*<p className={classes.cardCategory}>Used Limits</p>*/}
                            {/*<h3 className={classes.cardTitle}>*/}
                            {/*    279 <small>$</small>*/}
                            {/*</h3>*/}
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[t("regular"), t("premium"), t("super"), t("diesel")]}
                                // tableData={[["1", "2", "3", "4"]]}
                                tableData={this.parsePriceTableData()}
                                colorsColls={["primary"]}
                            />
                            {this.getButton()}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>{this.state.alert}</GridContainer>
            <GridContainer>{this.state.priceAlert}</GridContainer>
            <GridContainer justify={"center"}>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[t("company"), t("product"), t("quantity"), t("balance"), t("price"), t("total"), t("updated"), t("status")]}
                                tableData={this.parseTableData()}
                                coloredColls={[4]}
                                colorsColls={["primary"]}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    }
}

const enhance = compose(
    withTranslation(),
    withFirestore,

    firestoreConnect((props) => [
        {
            collection: 'requests',
            doc: "product",
            subcollections: [
                {
                    collection: "petrol", // ToDo change product category
                    where: [
                        ['updated', '>=', new props.firestore.Timestamp(Date.now()/1000 - 31540000, 0)],
                        ['requestorId', '==', props.firebase.auth().currentUser.uid]
                    ],
                    orderBy: ['updated', 'desc']
                }],
            storeAs: "requests"
        },
        {
            collection: 'prices',
            doc: props.firebase.auth().currentUser.uid,
            storeAs: "prices"
        }
    ]),
    connect((state) => ({
        user: state.firebase.profile,
        requests: state.firestore.ordered.requests,
        prices: state.firestore.ordered.prices,
    })),
    withHandlers({
        addPrices: props => (newPrices) => (
            props.firestore.collection("prices").doc(props.firebase.auth().currentUser.uid).set(newPrices)
        ),
        confirm: props => (productId) => (
            props.firestore.collection("requests").doc("product").collection("petrol").doc(productId).update({confirmed: true, updated: props.firestore.Timestamp.now()})
        ),
        decline: props => (productId) => (
            props.firestore.collection("requests").doc("product").collection("petrol").doc(productId).update({decline: true, updated: props.firestore.Timestamp.now()})
        ),

        setProductPrice: props => (productId, price) => (
            props.firestore.collection("requests").doc("product").collection("petrol").doc(productId).update({priceConfirmed: true, price: price.toString(), updated: props.firestore.Timestamp.now()})
        )
    }),
    withStyles(dashboardViewStyle),
    spinnerWhileLoading(['requests']),
    spinnerWhileLoading(['prices'])
);

export default enhance(RequestsDashboardView);