// Paths
import {
    HOME,
    EMPTY,
    MERCHANT,
    DASHBOARD,
    COMPANY as COMPANY_ROUTE,
    PROVIDER as PROVIDER_ROUTE,
    REQUESTS,
    SIGN_OUT,
    REPORTS,
    NOT_FOUND,
    STAFF,
    RULES,
    MANAGEMENT as MANAGEMENT_ROUTE,
    STORE as STORE_ROUTE,
    EMPLOYEE as EMPLOYEE_ROUTE
} from "../config/routePaths";

// Company types
import { PROVIDER, STORE, MANAGEMENT, COMPANY, EMPLOYEE } from "../config/companyTypes";

// Icons
import DashboardIcon from "../icons/dashboard";
import PetrolIcon from "../icons/petrol";
import ReportIcon from "../icons/report";
import Sign from "../icons/sign";

//Views
import RequestsView from "../pages/Dashboard/views/RequestsView";
import LogOutView from "../pages/Auth/views/SignOut";
import ProviderDashboardView from "../pages/Dashboard/views/ProviderDashboardView";
import StoreDashboardView from "../pages/Dashboard/views/StoreDashboardView";
import ManagementDashboardView from "../pages/Dashboard/views/ManagementDashboardView";
import CompanyDashboardView from "../pages/Dashboard/views/CompanyDashboardView";
import ManageRulesPage from "../pages/Dashboard/views/ManageRulesPage";
import Person from "../icons/person";
import Check from "../icons/check";
import ManageStaffPage from "../pages/Dashboard/views/ManageStaffPage";
import ProfileView from "../pages/Dashboard/views/ProfileView";
import ReportView from "../pages/Dashboard/views/ReportView";

export default  [

    //Provider Components
    {
        path: DASHBOARD,
        name: "dashboard",
        icon: DashboardIcon,
        component: ProviderDashboardView,
        layout: PROVIDER,
        type: PROVIDER_ROUTE
    },

    {
        path: REQUESTS,
        name: "requests",
        icon: PetrolIcon,
        component: RequestsView,
        layout: PROVIDER,
        type: PROVIDER_ROUTE
    },

    {
        path: REPORTS,
        name: "reports",
        icon: ReportIcon,
        component: ReportView,
        layout: PROVIDER,
        type: PROVIDER_ROUTE
    },

    {
        path: SIGN_OUT,
        name: "logout",
        icon: Sign,
        component: LogOutView,
        layout: PROVIDER,
        type: PROVIDER_ROUTE
    },

    //Company Components

    {
        path: DASHBOARD,
        name: "dashboard",
        icon: DashboardIcon,
        component: CompanyDashboardView,
        layout: COMPANY,
        type: COMPANY_ROUTE
    },

    {
        path: RULES,
        name: "financial_rules",
        icon: Check,
        component: ManageRulesPage,
        layout: COMPANY,
        type: COMPANY_ROUTE
    },

    {
        path: STAFF,
        name: "staff",
        icon: Person,
        component: ManageStaffPage,
        layout: COMPANY,
        type: COMPANY_ROUTE
    },

    {
        path: SIGN_OUT,
        name: "logout",
        icon: Sign,
        component: LogOutView,
        layout: COMPANY,
        type: COMPANY_ROUTE
    },

    //Employee Components

    {
        path: DASHBOARD,
        name: "dashboard",
        icon: DashboardIcon,
        component: ProfileView,
        layout: EMPLOYEE,
        type: EMPLOYEE_ROUTE
    },

    //Store Components

    {
        path: DASHBOARD,
        name: "dashboard",
        icon: DashboardIcon,
        component: StoreDashboardView,
        layout: STORE,
        type: STORE_ROUTE
    },

    {
        path: DASHBOARD,
        name: "dashboard",
        icon: DashboardIcon,
        component: ManagementDashboardView,
        layout: MANAGEMENT,
        type: MANAGEMENT_ROUTE
    }
];
