const content = {
    translation: {
        // Common words
        "settings": "Settings",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "may": "May",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep": "Sep",
        "oct": "Oct",
        "nov": "Nov",
        "dec": "Dec",

        "l": "L",
        "amd": "AMD",
        "premium": "Premium",
        "regular": "Regular",
        "super": "Super",
        "diesel": "Diesel",

        "all": "All",
        "present": "Present",
        "from": "From",
        "to": "To",
        "select": "Select",
        "cancel": "Cancel",
        "ok": "OK",
        "reset": "Reset",
        "filter": "Filter",
        "total": "Total",
        "price": "Price",
        "updated": "Updated",
        "id": "ID",
        "closed": "Closed",
        "active": "Active",
        "status": "Status",
        "balance": "Balance",

        "company": "Company",
        "deactivated": "Deactivated",
        "used_limits": "Used",
        "detailed": "Detailed",
        "name": "Name",
        "amount": "Amount",
        "financial_rules": "Financial Rules",
        "staff": "Staff",
        "rules_list": "Rules List",
        "rule_name": "Rule Name",
        "type": "Type",
        "due_date": "Due Date",
        "assigned_people": "Assigned People",
        "lunch": "Lunch",

        "staff_list": "Staff List",
        "hr": "HR",
        "department": "Department",

        "not_allowed_message": "This action is not allowed for this user. Please Call +374 44 729 828 for questions",


        // Abbreviations
        "settings_abbreviation": "S",

        // NavBar Content
        "home": "Home",

        //Login Page
        "log_in": "Log In",
        "email_hint": "Email...",
        "logout": "Log Out",
        "password_hint": "Password...",
        "login_button": "Let's Go",

        // Dashboard SideBar
        "edit_profile": "Edit Profile",
        "edit_profile_abbreviation": "EP",
        "my_profile": "My Profile",
        "my_profile_abbreviation": "MP",

        "dashboard": "Dashboard",
        "requests": "Requests",
        "check_requests": "Check Requests",
        "request_footer_text": "Number of total requests for this year",

        // ---------- Pages -------------

        // Maintenance
        "title": "Website maintenance",
        "header1": "We'll Back Soon",

        // Dashboard Provider
        "no_requests_found": "No any active request found",
        "no_payment_found": "No any payment found",
        "date": "Date",
        "time": "Time",
        "product": "Product",
        "quantity": "Quantity",
        "store": "Store",
        "pick_time_period": "Pick time period",
        "dailyPrice": "Price for the person"
    }
};

export default content;