// Redux
import { compose } from 'redux'
import { withHandlers } from 'recompose'

import { withStyles } from "@material-ui/core/styles";
import styles from "./style/loginViewStyle";

// Firebase
import { withFirebase } from 'react-redux-firebase'

// Translation
import { withTranslation } from 'react-i18next';

import LoginView from "./LoginView";


const enhance = compose(
    withStyles(styles),
    withFirebase,
    withTranslation(),
    withHandlers({
        login: props => (loginCredentials) => (

            props.firebase.login(
                {
                    email: loginCredentials.email,
                    password: loginCredentials.password
                }
            )
        )
    })
);

export default enhance(LoginView);