import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const Check = (props) => (
    <SvgIcon viewBox='0 0 49 95.3' {...props}>
        <g>
            <rect y="15.8" width="49" height="4.2"/>
            <rect y="76.3" width="49" height="4.2"/>
            <rect x="44.8" y="23.3" width="4.2" height="49.4"/>
            <rect x="0" y="23.3" className="st0" width="4.2" height="49.4"/>
            <path d="M39.3,28.2v4.2H9.7v-4.2H39.3z M39.3,37v4.2H9.7V37H39.3z M22.1,46.2v4.2H9.7v-4.2H22.1z"/>
            <path d="M21.5,7.4l-2.9,3.5l-7.9-6.4l-7.9,6.4L0,7.4L9.1,0h3.2L21.5,7.4z"/>
            <path d="M49,7.4l-2.9,3.5l-7.9-6.4l-7.9,6.4l-2.9-3.5L36.6,0h3.2L49,7.4z"/>
            <path d="M27.5,87.9l2.9-3.5l7.9,6.4l7.9-6.4l2.9,3.5l-9.1,7.4h-3.2L27.5,87.9z"/>
            <path d="M0,87.9l2.9-3.5l7.9,6.4l7.9-6.4l2.9,3.5l-9.1,7.4H9.1L0,87.9z"/>

        </g>
    </SvgIcon>
);

export default Check;