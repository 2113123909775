import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const petrol = (props) => (
    <SvgIcon viewBox='0 0 102.5 58.7' {...props}>
        <g>
            <rect x="9.8" y="11.1" className={{fill: '#000000'}} width="4.7" height="36.6"/>
            <rect y="16.2" className={{fill: '#000000'}} width="4.7" height="26.3"/>
            <path className={{fill: '#000000'}} d="M10.4,0h12.9v58.7H10.4V54h8V4.8h-8V0z"/>
            <rect x="97.8" y="16.2" className={{fill: '#000000'}} width="4.7" height="26.3"/>
            <rect x="88" y="11.1" className={{fill: '#000000'}} width="4.7" height="36.6"/>
            <path className={{fill: '#000000'}} d="M92.1,58.7H79.2V0h12.9v4.8h-8V54h8V58.7z"/>
            <rect x="25.8" y="27" className={{fill: '#000000'}} width="50.9" height="4.7"/>
        </g>
    </SvgIcon>
);

export default petrol;