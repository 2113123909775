import React from 'react';
import ReactDOM from 'react-dom';

// Content with translation
import "./content/i18n";

// Server - Firebase
// Initialization of the firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore' // make sure you add this for firestore
// Firebase configuration
import { firebaseConfig, rrfConfig } from './config/firebaseConfig'

// Store/Reducer
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import makeStore from './store/makeStore'

// Application
import App from './App';

// Initialize Firebase instance
firebase.initializeApp(firebaseConfig);
// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
    firebase: { authError: null }
};
const store = makeStore(initialState, firebase);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
};

const app = (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App />
        </ReactReduxFirebaseProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
