import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import authReducer from './authReducers'
import { reducer as form } from 'redux-form'

export default function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore
    form,
    authReducer,
    // notifications,
    // location: locationReducer,
    ...asyncReducers
  })
}