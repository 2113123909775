import React from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import withStyles from "@material-ui/core/styles/withStyles";

import { withTranslation } from "react-i18next";
import {DateRange} from "react-date-range";

import { grayColor } from "../../../assets/jss/payva-dashboard";

import buttonStyle from "../../../components/CustomButtons/style/buttonStyle";

const sweetAlertStyle = {
    cardTitle: {
        marginTop: "0",
        marginBottom: "3px",
        color: grayColor[2],
        fontSize: "18px"
    },
    center: {
        textAlign: "center"
    },
    right: {
        textAlign: "right"
    },
    left: {
        textAlign: "left"
    },
    ...buttonStyle
};

class CalendarPickerView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visibility: false,
            dateRange: {
                selection: {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                }
            }
        };

        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.onConfirm = props.onConfirm;
        this.onFilter = null;
        this.onCancel = props.onCancel;
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    showCalendarPickerView(onChangeMethod) {
        this.onFilter = onChangeMethod;
        this.toggleVisibility()
    }

    toggleVisibility() {
        this.setState({
                ...this.state,
                visibility: !this.state.visibility
            })
    }

    handleRangeChange(which, payload) {
        console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
    }

    successDatePick() {
        if (this.onFilter !== null || true) {
            this.onFilter(this.state.dateRange.selection);
        }
        this.onConfirm(this.state.dateRange.selection);
        this.toggleVisibility()
    }

    successDateReset() {
        const currentDate = new Date();
        if (this.onFilter !== null || true) {
            this.onFilter({startDate: "01 01 2019", endDate: currentDate.toString()})
        }
        this.onConfirm({startDate: "01 01 2019", endDate: currentDate.toString()});
        this.toggleVisibility()
    }

    render() {
        const {classes, t} = this.props;

        if (this.state.visibility) {
            return <SweetAlert
                        showCloseButton
                        title={t("pick_time_period")}
                        onConfirm={() => this.successDatePick()}
                        onCancel={() => this.successDateReset()}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                        cancelBtnCssClass={classes.button + " " + classes.danger}
                        confirmBtnText={t("select")}
                        cancelBtnText={t("reset")}
                        showLoaderOnConfirm
                        showCancel
                    >
                <DateRange
                    onChange={this.handleRangeChange.bind(this, 'dateRange')}
                    moveRangeOnFirstSelection={false}
                    ranges={[this.state.dateRange.selection]}
                />
            </SweetAlert>
        } else {
            return null
        }
    }

}

export default withStyles(sweetAlertStyle)(withTranslation()(CalendarPickerView));