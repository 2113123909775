import React from "react";

import { withTranslation } from "react-i18next";
import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table";

class DashboardCardHeaderView extends React.Component {

    constructor(props) {
        super(props);
        const currentDate = new Date();
        this.state = {
            currentDate: currentDate,
            fromDate: new Date(2019, 0, 1),
            toDate: currentDate,
            paymentsList: props.paymentsList,
            productName: "",
            storesList: props.storesList,
            storeName: "",
            time: ""
        };

        this.changeProduct = this.changeProduct.bind(this);
        this.updateDatePeriod = this.updateDatePeriod.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    changeProduct(productName) {
        this.setState({
                ...this.state,
                productName: productName
            })
    }

    changeStore(store) {
        this.setState({
            ...this.state,
            storeName: store
        })
    }

    resetUsage() {
        return {
            regular: 0,
            premium: 0,
            super: 0,
            diesel: 0
        }
    }

    getStoreName() {
        const {t} = this.props;
        if (this.state.storeName === "") {
            return t("all")
        } else {
            return this.state.storeName
        }
    }


    getUsage() {
        const usage = this.resetUsage();

        this.state.paymentsList.forEach(doc => {
            const currentData = doc.data();
            if (this.state.storeName === "" || this.state.storeName === this.state.storesList[currentData.store]) {
                const currentDate = currentData.updated.toDate();

                const toDate = this.state.toDate.setHours(23, 59, 59, 59);

                if (currentDate >= this.state.fromDate && currentDate <= toDate) {
                    if (this.state.productName === "" || this.state.productName === currentData.product) {
                        usage[currentData.product] += currentData.quantity;
                    }
                }
            }
            // content.push({
            //     id: doc.id,
            //     date: this.formatDate(currentData.updated.toDate()),
            //     time: this.formatTime(currentData.updated.toDate()),
            //     product: this.props.t(currentData.product),
            //     quantity: currentData.quantity,
            //     store: this.props.user.stores[currentData.store],
            //     company: currentData.payerName
            // });
        });

        return usage;
    };

    updateDatePeriod(dateRange) {
        this.setState({
            ...this.state,
            fromDate: new Date(dateRange.startDate),
            toDate: new Date(dateRange.endDate)
        })
    }

    timeFilterView() {
        const  {t} = this.props;
        if (this.state.time === "") {
            return <GridItem xs={4}>
                <h6 style={{textAlign: "center"}}>{t("from")} <span style={{color: "black"}}>{this.state.fromDate.toDateString()}</span></h6>
                <h6 style={{textAlign: "center"}}>{t("to")} <span style={{color: "black"}}>{this.state.toDate.toDateString()}</span></h6>
            </GridItem>
        }
    }

    render() {
        const usage = this.getUsage();
        const {t} = this.props;
        return <Table
                tableHead={[t("date"), t("regular"), t("premium"), t("super"), t("diesel"), t("store")]}
                tableData={[
                    [this.state.fromDate.toDateString() + "\n" + this.state.toDate.toDateString(), usage.regular, usage.premium, usage.super, usage.diesel, this.getStoreName()]
                ]}
            />
    }

}

export default withTranslation()(DashboardCardHeaderView);