// Redux
import { compose } from 'redux'

// Firebase
import { withFirebase } from 'react-redux-firebase'

// Translation
import { withTranslation } from 'react-i18next';

// Component
import Auth from "./Auth";

const enhance = compose(
    withFirebase,
    withTranslation()
);

export default enhance(Auth);