import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const Report = (props) => (
    <SvgIcon viewBox='0 0 73.3 98' {...props}>
        <g>
            <rect x="12.8" y="16.1" width="4.5" height="4.3"/>
            <rect x="12.8" y="28.4" width="4.5" height="4.3"/>
            <rect x="12.8" y="40.7" width="4.5" height="4.3"/>
            <rect x="12.8" y="53"   width="4.5" height="4.3"/>
            <rect x="12.8" y="65.3" width="4.5" height="4.3"/>
            <rect x="12.8" y="77.7" width="4.5" height="4.3"/>
            <rect x="22.3" y="16.1" width="38.2" height="4.3"/>
            <rect x="22.3" y="28.4" width="38.2" height="4.3"/>
            <rect x="22.3" y="40.7" width="38.2" height="4.3"/>
            <rect x="22.3" y="53"   width="38.2" height="4.3"/>
            <rect x="22.3" y="65.3" width="38.2" height="4.3"/>
            <rect x="22.3" y="77.7" width="38.2" height="4.3"/>
            <g>
                <rect x="7.5"  width="27.2" height="4.3"/>
                <rect x="39.3" width="27.4" height="4.3"/>
                <rect x="39.3" y="93.8" width="27.4" height="4.3"/>
                <rect y="8.6" width="4.3" height="38.3"/>
                <rect x="69" y="8.6" width="4.3" height="38.3"/>
                <rect y="51.2" width="4.3" height="38.3"/>
                <rect x="69" y="51.2" width="4.3" height="38.3"/>
                <rect x="7.3" y="93.8" width="27.4" height="4.3"/>
            </g>

        </g>
    </SvgIcon>
);

export default Report;