import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const food = (props) => (
    <SvgIcon viewBox='0 0 105.1 72.8' {...props}>
        <g>
            <rect x="89.7" y="31.6" className={{fill: '#000000'}} width="5.1" height="30.7"/>
            <rect x="89.7" y="67.6" className={{fill: '#000000'}} width="5" height="5.1"/>
            <polygon className={{fill: '#000000'}} points="91.4,0 86.6,1.5 100.3,45.2 105.1,43.7 	"/>
            <rect x="4.9" y="67.6" className={{fill: '#000000'}} width="4.9" height="5.1"/>
            <rect x="4.8" y="31.6" className={{fill: '#000000'}} width="5.1" height="30.7"/>
            <rect x="10.1" className={{fill: '#000000'}} width="4.8" height="27"/>
            <rect className={{fill: '#000000'}} width="4.8" height="27"/>
            <path className={{fill: '#000000'}} d="M74.5,20.8l-3-4.3l-1.2-1.3l-4.3-3l-4.8-2l-7.1-1.4h-6.4l-7.1,1.4l-4.9,2l-3,2.1l-1.3,0.9L30,16.4l-1,1.4l-2,2.9l-2,4.8l-1.4,7.1V39l1.4,7.1l2,4.9l3,4.3l1.2,1.3l4.3,3l4.8,2l7.1,1.4h6.4l7.1-1.4l4.9-2l3-2.1l1.3-0.9l1.3-1.3l1-1.4l2-2.9l2-4.8l1.4-7.1v-6.4l-1.4-7.1L74.5,20.8z M73.1,38.6L72,44.4l-1.8,4.8l-1,1.4l-1.5,2.1l0,0l-2.2,1.4l-1.5,1l-4.7,1.8l-5.8,1.2H48l-5.8-1.2l-4.8-1.8l-3.6-2.4l0,0l-2.5-3.7l-1.8-4.7l-1.2-5.8v-5.5l1.2-5.8l1.8-4.8l1-1.5l1.5-2.1l0,0l2.2-1.4l1.5-1l4.7-1.8l5.8-1.2h5.5l5.8,1.2l4.8,1.8l3.6,2.4l0,0l2.5,3.7l1.8,4.7l1.2,5.8V38.6z"/>
        </g>
    </SvgIcon>
);

export default food;