import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// Paths
import { HOME_FULL_PATH } from "../../config/routePaths";

// core components
import Button from "../CustomButtons/Button";

// Style
import styles from "./style/authNavbarStyle";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {

  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button href={HOME_FULL_PATH} className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};