import React from "react";

// Core Components
// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardIcon from "../../../components/Card/CardIcon";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// Style of the main page
import withStyles from "@material-ui/core/styles/withStyles";
import manageRulesPageStyle from "../style/manageRulesPageStyle";
import ReactTable from "react-table";
import {withTranslation} from "react-i18next";
import Button from "../../../components/CustomButtons/Button";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

class ManageStaffPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    id: "1001042",
                    name: "Լիլիթ Ավետիքյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001055",
                    name: "Մանե Գրիգորյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001097",
                    name: "Ժաննա Սուսլոնովա",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001123",
                    name: "Անի Սարգսյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001083",
                    name: "Նորա Դավթյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001053",
                    name: "Արփինե Մելքոնյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001249",
                    name: "Աննա Բարսեղյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                },
                {
                    id: "1001127",
                    name: "Սիրվարդ Արղամանյան",
                    department: props.t("hr"),
                    rules: 1,
                    action: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            {/* use this button to add a edit kind of action */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    alert(props.t("not_allowed_message"));
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            {/* use this button to remove the data row */}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {alert(props.t("not_allowed_message"));}}
                                color="danger"
                                className="remove"
                            >
                                <Close />
                            </Button>{" "}
                        </div>
                    )
                }
            ]
        };
    }
    render() {
        const {t, classes} = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} lg={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <Assignment />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>{t("staff_list")}</h4>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={this.state.data}
                                    filterable
                                    columns={[
                                        {
                                            Header: t("id"),
                                            accessor: "id"
                                        },
                                        {
                                            Header: t("name"),
                                            accessor: "name"
                                        },
                                        {
                                            Header: t("department"),
                                            accessor: "department"
                                        },
                                        {
                                            Header: t("financial_rules"),
                                            accessor: "rules",
                                            sortable: false
                                        },
                                        {
                                            Header: " ",
                                            accessor: "action",
                                            sortable: false,
                                            filterable: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

export default withTranslation()(withStyles(manageRulesPageStyle)(ManageStaffPage))