import React from "react";
import { Route } from "react-router-dom";

// Authentication Security
import { UserIsNotAuthenticated } from "../../network/auth";

import AuthPage from "./page";
import {LOGIN} from "../../config/routePaths";

// Sync route definition
export default {
    route: <Route path={LOGIN} component={UserIsNotAuthenticated(AuthPage)} />
}