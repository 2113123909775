// --- Firebase Initialization --- //
// Configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBzdBvU3jqqmkC1I3d0ibVpJZR2cOlmoW0",
    authDomain: "payva-payment.firebaseapp.com",
    databaseURL: "https://payva-payment.firebaseio.com",
    projectId: "payva-payment",
    storageBucket: "payva-payment.appspot.com",
    messagingSenderId: "111197744880",
    appId: "1:111197744880:web:3ac71407216419889d1c35",
    measurementId: "G-ZK0W1NLN1Z"
};

export const reduxFirebase = {
    enableLogging: false, // enable/disable Firebase Database Logging
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    updateProfileOnLogin: false, // enable/disable updating of profile on login
};

// ======================================================
// Redux + Firebase Config (react-redux-firebase & redux-firestore)
// ======================================================
export const defaultRRFConfig = {
    userProfile: 'users', // root that user profiles are written to
    presence: 'presence', // list currently online users under "presence" path in RTDB
    // ToDo Activate Sessions
    // sessions: 'sessions', // Save sessions set to null if need to skip
    // profileDecorator: (userData) => ({ email: userData.email }) // customize format of user profile
};

// Combine default config with overrides if they exist (set within .firebaserc)
export const rrfConfig = reduxFirebase
    ? { ...defaultRRFConfig, ...reduxFirebase }
    : defaultRRFConfig;

export default {
    defaultRRFConfig,
    firebaseConfig,
    rrfConfig,
    reduxFirebase
}
