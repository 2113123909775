import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const petrol = (props) => (
    <SvgIcon viewBox='0 0 102.6 91.3' {...props}>
        <g>
            <path className={{fill: '#000000'}} d="M101.3,67.9l-1.2,1.7l-2.5,2l-2,0.9L94,72.8h-2.1l-1.6-0.4l-2-0.9l-2.5-2l-1.2-1.7l-0.9-2l-0.4-1.7v-2l0.4-1.7l0.9-2l1.2-1.7l2.5-2l2-0.9l1.6-0.4H94l1.6,0.4l2,0.9l2.5,2l1.2,1.7l0.9,2l0.4,1.7v2l-0.4,1.7L101.3,67.9z M97.7,60.3l-0.8-1l-1.5-1.2l-1-0.4l-0.9-0.2h-1l-0.9,0.2l-1,0.4L89,59.4l-0.8,1l-0.5,1l-0.2,1.1v1.3l0.2,1.1l0.5,1l0.8,1l1.5,1.2l1,0.4l0.9,0.2h1l0.9-0.2l1-0.4l1.5-1.2l0.8-1l0.5-1l0.2-1.1v-1.3l-0.2-1.1L97.7,60.3z"/>
            <path className={{fill: '#000000'}} d="M18,67.9l-1.2,1.7l-2.5,2l-2,0.9l-1.6,0.4H8.6L7,72.4l-2-0.9l-2.5-2l-1.2-1.7l-0.9-2L0,64.1v-2l0.4-1.7l0.9-2l1.2-1.7l2.5-2l2-0.9l1.6-0.4h2.1l1.6,0.4l2,0.9l2.5,2l1.2,1.7l0.9,2l0.4,1.7v2l-0.4,1.7L18,67.9z M14.4,60.3l-0.8-1l-1.5-1.2l-1-0.4l-0.9-0.2h-1l-0.9,0.2l-1,0.4l-1.5,1.2l-0.8,1l-0.5,1l-0.2,1.1v1.3l0.2,1.1l0.5,1l0.8,1l1.5,1.2l1,0.4l0.9,0.2h1l0.9-0.2l1-0.4l1.5-1.2l0.8-1l0.5-1l0.2-1.1v-1.3l-0.2-1.1L14.4,60.3z"/>
            <rect y="46.3" className={{fill: '#000000'}} width="102.6" height="4.8"/>
            <rect y="75.2" className={{fill: '#000000'}} width="102.6" height="4.8"/>
            <polygon className={{fill: '#000000'}} points="17.5,16.1 17.5,40.7 21.9,40.7 21.9,20.3 80.7,20.3 80.7,40.7 85.2,40.7 85.2,16.1 	"/>
            <polygon className={{fill: '#000000'}} points="38.4,0 38.4,13.4 42.8,13.4 42.8,4.2 58.8,4.2 58.8,13.4 63.2,13.4 63.2,0 	"/>
            <g>
                <path className={{fill: '#000000'}} d="M87.3,85.6l0.4-1.2v-1.1h4.2v1.9l-0.9,2.4l-3,2.7l-3.1,1h-2.5l-3.1-1l-3-2.7l-0.9-2.4v-1.9h4.2v1.1l0.4,1.2l1.7,1.4l1.5,0.5h0.9l1.5-0.5L87.3,85.6z"/>
                <path className={{fill: '#000000'}} d="M22.6,85.6l0.4-1.2v-1.1h4.2v1.9l-0.9,2.4l-3,2.7l-3.1,1h-2.5l-3.1-1l-3-2.7l-0.9-2.4v-1.9h4.2v1.1l0.4,1.2l1.7,1.4l1.5,0.5h0.9l1.5-0.5L22.6,85.6z"/>
            </g>
            <path className={{fill: '#000000'}} d="M64.8,55.7v4.8h-27v-4.8H64.8z M64.8,65.8v4.8h-27v-4.8H64.8z"/>
        </g>
    </SvgIcon>
);

export default petrol;