// Redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, withFirebase } from 'react-redux-firebase'
import { withHandlers } from 'recompose'

import { spinnerWhileLoading } from "../../../network/auth";

// Translation
import { withTranslation } from 'react-i18next';

// Components
import Dashboard from "./Dashboard";

const enhance = compose(
    withFirebase,
    withTranslation(),
    firestoreConnect((props) => [
        { collection: 'users', doc: props.firebase.auth().currentUser.uid }
    ]),

    withHandlers({
        login: props => (loginCredentials) => (

            props.firebase.login(
                {
                    email: loginCredentials.email,
                    password: loginCredentials.password
                }
            )
        )
    }),
    connect(({ firestore: { data } }, props) => ({
        user: data.users && data.users[props.firebase.auth().currentUser.uid],
        logout: props.firebase.auth().signOut
    })),
    spinnerWhileLoading(['user']),
);

export default enhance(Dashboard)