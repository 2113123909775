import {
    successColor,
    tooltip,
    cardTitle,
    grayColor} from "../../../assets/jss/payva-dashboard";

import buttonStyle from "../../../components/CustomButtons/style/buttonStyle";

const merchantStoreDemoDashboardStyle = theme => ({
    cardHover: {
        "&:hover": {
            "& $cardHeaderHover": {
                transform: "translate3d(0, -50px, 0)"
            }
        }
    },
    cardHeaderHover: {
        transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
    },
    cardHoverUnder: {
        position: "absolute",
        zIndex: "1",
        top: "-50px",
        width: "calc(100% - 30px)",
        left: "17px",
        right: "17px",
        textAlign: "center"
    },
    centerOfTheScreen: {
        height: "200px",
        width: "400px",
        position: "fixed",
        top: "50%",
        left: "50%",
        marginTop: "-100px",
        marginLeft: "-200px",
        display: "flex",
        justifyContent: "center",
        fontSize: 24
    },
    cardCategoryWhite: {
        margin: "0",
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: ".875rem"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: "#FFFFFF",
        marginTop: "0"
    },
    tooltip,
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
        textAlign: "center"
    },
    cardCategory: {
        color: grayColor[0],
        fontSize: "14px",
        paddingTop: "10px",
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardProductDesciprion: {
        textAlign: "center",
        color: grayColor[0]
    },
    stats: {
        color: grayColor[0],
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
    productStats: {
        paddingTop: "7px",
        paddingBottom: "7px",
        margin: "0"
    },
    successText: {
        color: successColor[0]
    },
    upArrowCardCategory: {
        width: 14,
        height: 14
    },
    underChartIcons: {
        width: "17px",
        height: "17px"
    },
    price: {
        color: "inherit",
        "& h4": {
            marginBottom: "0px",
            marginTop: "0px"
        }
    },

    ...buttonStyle
});

export default merchantStoreDemoDashboardStyle;