export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const AUTH_REDIRECT = 'AUTH_REDIRECT';
export const UN_AUTH_REDIRECT = 'UN_AUTH_REDIRECT';

export default {
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SIGN_OUT_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_UP_ERROR,
    AUTH_REDIRECT,
    UN_AUTH_REDIRECT
}