import {
    cardTitle
} from "../../../assets/jss/payva-dashboard";

const manageRulesPageStyle = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

export default manageRulesPageStyle;