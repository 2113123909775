import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const Dashboard = (props) => (
    <SvgIcon viewBox='0 0 85.5 83' {...props}>
        <g>
            <rect className="st0" width="39.7" height="4.3"/>
            <rect y="12.3" className="st0" width="39.7" height="4.3"/>
            <rect y="24.7" className="st0" width="39.7" height="4.3"/>
            <rect y="37" className="st0" width="39.7" height="4.3"/>
            <rect y="78.7" className="st0" width="39.7" height="4.3"/>
            <rect y="39.2" className="st0" width="4.3" height="40.7"/>
            <rect x="35.4" y="39.2" className="st0" width="4.3" height="40.7"/>
            <rect x="45.8" className="st0" width="39.7" height="4.3"/>
            <rect x="45.8" y="41.7" className="st0" width="39.7" height="4.3"/>
            <rect x="45.8" y="2.1" className="st0" width="4.3" height="40.7"/>
            <rect x="81.3" y="2.1" className="st0" width="4.3" height="40.7"/>
            <rect x="45.8" y="54" className="st0" width="39.7" height="4.3"/>
            <rect x="45.8" y="66.4" className="st0" width="39.7" height="4.3"/>
            <rect x="45.8" y="78.7" className="st0" width="39.7" height="4.3"/>
        </g>
    </SvgIcon>
);

export default Dashboard;