export const HOME = '/';
export const HOME_FULL_PATH = "https://payva.io";
export const LOGIN = '/login';
export const EMPTY = '';
export const NOT_FOUND = "/error";
export const SIGN_OUT = "/logout";

export const DASHBOARD = '/dashboard';
export const REQUESTS = "/requests";
export const REPORTS = "/reports";
export const MERCHANT = '/merchant';
export const MANAGEMENT = '/management';
export const EMPLOYEE = '/employee';
export const STORE = '/store';
export const COMPANY = '/company';
export const PROVIDER = '/provider';
export const RULES = '/rules';
export const STAFF = '/staff';
// export const PRICE = '/price';
// export const DEMO = '/demo';
// export const REGISTER = AUTH + '/#view=register';
// export const LOGIN = AUTH + '/#view=login';
// export const MERCHANT_HASH = ',entity=merchant';
// export const COMPANY_HASH = ',entity=company';
