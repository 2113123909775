import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const petrol = (props) => (
    <SvgIcon viewBox='0 0 91.9 99.1' {...props}>
        <g>
            <path className={{fill: '#000000'}} d="M64.4,80.1V50.7h4.4v29.4H64.4z"/>
            <path className={{fill: '#000000'}} d="M77.3,80.1V41.4h4v38.7H77.3z"/>
            <path className={{fill: '#000000'}} d="M43.6,28.2V33h-27v-4.8H43.6z M43.6,38.3v4.8h-27v-4.8H43.6z"/>
            <path className={{fill: '#000000'}} d="M60.3,85.8v13.3H0V85.8h4.5v8.8h51.4v-8.8H60.3z"/>
            <path className={{fill: '#000000'}} d="M0,13.3V0h60.3v13.3h-4.5V4.4H4.4v8.8H0z"/>
            <path className={{fill: '#000000'}} d="M13.3,79.7H0V19.4h13.3v4.5H4.4v51.4h8.8V79.7z"/>
            <path className={{fill: '#000000'}} d="M47,19.4h13.3v60.3H47v-4.5h8.8V23.8H47V19.4z"/>
            <path className={{fill: '#000000'}} d="M66.6,8.9l3.1-3.3l9.8,9.2l-3.1,3.3L66.6,8.9z"/>
            <path className={{fill: '#000000'}} d="M89.8,34.5L88.5,36l-2.8,1.7l-2.1,0.6l-1.6,0.1l-2.1-0.3l-1.5-0.6l-1.9-1.1L74.2,34l-0.9-1.8l-0.6-2.1l-0.2-1.8l0.3-2l0.7-1.6l1.1-1.9l1.4-1.5l2.8-1.7l2.1-0.6l1.6-0.1l2.1,0.3l1.5,0.6l1.9,1.1l2.2,2.3l0.9,1.8l0.6,2.1l0.2,1.8l-0.3,2L91,32.6L89.8,34.5z M87.3,26.5l-0.7-1.1l-1.3-1.4l-1-0.6l-0.9-0.3l-1-0.1l-0.9,0.1l-1.1,0.3l-1.6,1l-0.9,0.9l-0.6,1l-0.4,1.1l-0.2,1.3l0.1,1.1l0.3,1.1l0.7,1.1l1.3,1.4l1,0.6l0.9,0.3l1,0.1l0.9-0.1l1.1-0.3l1.6-1l0.9-0.9l0.6-1l0.4-1.1l0.2-1.3l-0.1-1.1L87.3,26.5z"/>
            <path className={{fill: '#000000'}} d="M76.7,88.1l0.4-1.2v-1.1h4.2v1.9l-0.9,2.4l-3,2.7l-3.1,1h-2.5l-3.1-1l-3-2.7l-0.9-2.4v-1.9H69v1.1l0.4,1.2l1.7,1.4l1.5,0.5h0.9l1.5-0.5L76.7,88.1z"/>
        </g>
    </SvgIcon>
);

export default petrol;