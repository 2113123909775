import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const payvaHorizontalWhiteIcon = (props) => (
    <SvgIcon viewBox='0 0 732.2 164.9' {...props}>
        <g>
            <g>
                <path className={{fill: '#FFFFFF'}} d="M255,34h16.9l17.5,8.1l11.5,15.5l3.5,12v20.1l-3.5,12.3l-11.5,15.5l-16.9,8.1h-16.7l-10.1-3.7l-4.7-3v38.3h-14V35.3h13.8v8.9l3.7-3.9L255,34z M251,109.7l6.9,2.2h12.5l10-5.4l8.1-10.8l2-7.9V71.4l-2-7.6l-8.1-11l-10.6-5.1h-12.8l-11,7.8l-4.9,7.3v38.3l3.5,4.4L251,109.7z"/>
                <path className={{fill: '#FFFFFF'}} d="M341.9,42.4l8.1-5.2l10.8-3.2h17.4l12.1,3l12.5,8.4l4.6,11.5V108l6.6,9.4l-11.3,8.1l-8.1-12.1l-3.9,4.4l-15.5,7.8H363l-11.5-3.5l-8.1-4.7l-9.4-13.7v-9.8l2.4-11.5l12.5-12.5l16.7-4.2h12.1l11.3,3.7l4.4,2.9V59.4l-1.9-5.1l-7.1-4.6l-8.3-2h-13.7l-7.3,2.4l-5.2,3.4l-3.2,3.2l-9.6-10L341.9,42.4z M393.3,94.5v-6.2l-3.5-3.7l-5.2-2.7l-8.4-2.4H367l-11.1,3l-6.6,6.6L348,95v4.6l5.2,7.4l3.9,2.4l7.9,2.4h7.1l12.7-4.7l6.9-7.3L393.3,94.5z"/>
                <path className={{fill: '#FFFFFF'}} d="M504.5,35.3H520l-56.8,121.8h-15.3l20.8-44.7L432,35.3h15.9l28.5,60.9L504.5,35.3z"/>
                <path className={{fill: '#FFFFFF'}} d="M555.1,35.3l29.9,70.5l29.9-70.5H630l-38.6,88.9h-12.8L540,35.3H555.1z"/>
                <path className={{fill: '#FFFFFF'}} d="M660.2,42.4l8.1-5.2l10.8-3.2h17.4l12.1,3l12.5,8.4l4.6,11.5V108l6.6,9.4l-11.3,8.1l-8.1-12.1l-3.9,4.4l-15.5,7.8h-12.1l-11.5-3.5l-8.1-4.7l-9.4-13.7v-9.8l2.4-11.5l12.5-12.5l16.7-4.2H696l11.3,3.7l4.4,2.9V59.4l-1.9-5.1l-7.1-4.6l-8.3-2h-13.7l-7.3,2.4l-5.2,3.4l-3.2,3.2l-9.6-10L660.2,42.4z M711.7,94.5v-6.2l-3.5-3.7l-5.2-2.7l-8.4-2.4h-9.1l-11.1,3l-6.6,6.6l-1.3,5.9v4.6l5.2,7.4l3.9,2.4l7.9,2.4h7.1l12.7-4.7l6.9-7.3L711.7,94.5z"/>
            </g>
            <g>
                <path className={{fill: '#FFFFFF'}} d="M0,0v41.2h185.5V0H0z M175.2,30.9H10.3V10.3h164.9V30.9z"/>
                <path className={{fill: '#FFFFFF'}} d="M0,61.8V103h185.5V61.8H0z M175.2,92.7H10.3V72.1h164.9V92.7z"/>
                <path className={{fill: '#FFFFFF'}} d="M0,123.7v41.2h185.5v-41.2H0z M175.2,154.6H10.3V134h164.9V154.6z"/>
            </g>
        </g>
    </SvgIcon>
);

export default payvaHorizontalWhiteIcon;