import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon/index';

const Location = (props) => (
    <SvgIcon viewBox='0 0 65.8 97.2' {...props}>
        <g>
            <rect x="26.8" width="26.2" height="4.3"/>
            <rect x="57.6" width="26.4" height="4.3"/>
            <rect x="20.3" y="7.6" width="4.3" height="29.6"/>
            <rect x="47.8" y="22.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -11.2453 45.9849)"
                  width="4.3" height="28.6"/>
            <rect x="35.6" y="59.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -28.839 53.2725)"
                  width="28.6" height="4.3"/>
            <rect x="20.3" y="60.8" width="4.3" height="29.6"/>
            <rect x="85.4" y="7.6" width="4.3" height="39.3"/>
            <rect y="46.9" width="51.2" height="4.3"/>
            <rect x="57.6" y="93.8" width="26.4" height="4.3"/>
            <rect x="85.4" y="51.2" width="4.3" height="39.3"/>
            <rect x="26.6" y="93.8" width="26.4" height="4.3"/>
        </g>
    </SvgIcon>
);

export default Location;